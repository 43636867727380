import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useAppDispatch, useAppSelector } from '../../store';
import Modal from '../Modal';
import { openModal } from '../Modal/store';
import { fetchGames, selectGames } from './store';
import { IGame, TParam } from './types';

type TGamesProps = {
  params?: TParam;
};

export const imagesRegisteredInGameShowCase: { codename: string; id: string }[] = [
  { codename: 'bg-001s-steampunk', id: '39573239-f13c-4a31-8a9e-f8dc5a010ab1' },
  { codename: 'p7-001s-mai-tai-punch', id: 'e98ecbf6-03ea-4ada-9e15-3da6d85fc257' },
  { codename: 'p7-002s-forbidden-pyramid', id: '5a6aacac-18c4-4a03-9457-98544dd385c6' },
  { codename: 'p7-003s-shark-rush', id: '977a152e-188e-49f8-b075-4c2c51f5fb30' },
  { codename: 'p7-004s-golden-pirate', id: '77b701b7-5210-4cfa-9327-d6d3ab9f3317' },
  { codename: 'p7-005s-paradise-delight', id: 'eba7f94f-c50c-493d-bedb-476e41061b8a' },
  { codename: 'p7-006s-dual-fusion', id: 'ae830e1b-268f-4154-aabd-46732a66d99b' },
  { codename: 'p7-007s-wild-four', id: '41a1ac68-f793-4b0b-b833-0c68e5c431c7' },
  { codename: 'p7-008s-ninja-strike', id: '18f3ab70-4155-401c-862d-7c5099e692f6' },
  { codename: 'p7-009s-meteor-of-chaos', id: '9776a55d-ac49-401b-9a9e-691b4b2c7683' },
  { codename: 'p7-010s-samurai-rumble', id: '1560c13f-4707-494d-922b-b49dd1dfacf1' },
  { codename: 'p7-011s-african-animals', id: 'f93898ef-5635-4276-af71-c417173572da' },
  { codename: 'p7-012s-hammer-thunder', id: '45069551-28bb-4af5-8965-2dea77488f3e' },
  { codename: 'p7-013s-mega-heist', id: 'ef804251-8f17-443e-ac4e-7f42c9b1caae' },
  { codename: 'p7-014s-pachicroon', id: 'eb66909f-c5c5-4b39-aa20-311cf6952caf' },
  { codename: 'p7-015s-dragon', id: 'e2187e05-b4bb-4d6b-8c88-c3fd4f03c083' },
  { codename: 'p7-016s-cardmaster', id: '812327ed-d1fe-4654-b7c6-8fc020b91325' },
  { codename: 'p7-017s-azai', id: '1f3d26dd-e3f5-4e7c-8b32-b618e335377d' },
  { codename: 'p7-018s-bookofwaifu', id: '92d46cfe-6399-4132-9246-643d36ad4b7e' },
  { codename: 'p7-021s-fujin-raijin', id: '435c7b5b-0f88-43d7-bb87-a1ce603b8fa4' },
  { codename: 'p7-027s-age-of-explorers', id: 'f96a4886-a701-41a7-9c90-f248003de888' },
  { codename: 'p7-028s-mavian-wreath', id: '6d617669-616e-4077-7265-617468e7c4be' },
  { codename: 'p7-030s-naughty-joker', id: '508bb990-dac3-4cab-ba15-6789391a5e85' },
  { codename: 'p7-031s-onsen-fortune', id: '6a031dd1-89bf-454e-893e-e0e6986fea70' },
  { codename: 'p7-034s-book-of-odin', id: '2ff24482-057f-4820-8bb1-b2d6e30961b7' },
  { codename: 'p7-037s-mrfirst-sakura', id: 'd0980ee6-51c4-428d-8491-412da8ff2550' },
  { codename: 'p7-041c-rally-rise', id: '9ca96cf8-2eb1-40db-94eb-f900da2030c6' },
  { codename: 'p7-042s-dice-of-chaos', id: 'dabe3cb6-c28d-41ad-8bba-1d54f82567fc' },
  { codename: 'p7-047s-sakura-fantasia-dice', id: '1c00895e-f7c2-4fcd-b6ad-b680386d23b3' },
  { codename: 'p7-048s-golden-pirate-dice', id: '2f241e18-db32-406d-a6ba-1b909e8555c0' },
  { codename: 'p7-049s-the-wild-four-dice', id: '204466d9-f59c-44a1-869a-4e28f9d7cf1f' },
  { codename: 'p7-050s-pachinca-dice', id: '7b3cc7d8-1a6a-41fd-ae57-dae5c4c80bd1' },
  { codename: 'p7-051s-mrfirst-bookofwaifu', id: '76e0435e-3c1d-4a83-ab9e-36700ca2b646' },
  { codename: 'xt-001s-dazzling-box', id: 'a7c653c1-d9cb-4e7b-982e-6edde5d38760' },
  { codename: 'xt-002s-pachinca', id: 'db8f3359-8589-45cc-9409-d7b5c6801764' },
  { codename: 'xt-004s-mystic-snowflakes', id: 'e3665667-fbf8-4ca1-801d-f900520ba3a9' },
];

export const getGameThumbnail = (game: IGame) => {
  if (imagesRegisteredInGameShowCase.find((v) => v.id === game.gameId)) {
    return `https://static.testing.devp7.com/game-showcase/latest/images/${
      imagesRegisteredInGameShowCase.filter((v) => v.id === game.gameId)[0].codename
    }.png`;
  }
  return `https://static.testing.devp7.com/legacy-lobby/${game.gameId}.png`;
};

export default function GameListCarousel(props: TGamesProps) {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useSessionContext();
  const renderPrevButton = ({ isDisabled }: { isDisabled?: boolean }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }} className="prev-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-arrow-left-square"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
      </span>
    );
  };

  const renderNextButton = ({ isDisabled }: { isDisabled?: boolean }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }} className="next-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-arrow-right-square"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
          />
        </svg>
      </span>
    );
  };

  React.useEffect(() => {
    if (props?.params || !games.length) {
      dispatch(fetchGames(props.params));
    }
  }, []);

  const onClick = React.useCallback((item: IGame) => {
    if (session.loading) {
      return;
    }
    if (item.tournament && session.userId) {
      return dispatch(openModal(item.gameName));
    } else {
      return navigate(`/play/${item.gameId}`);
    }
  }, []);

  const okButton = React.useCallback((item: IGame) => {
    return navigate(`/play/${item.gameId}`);
  }, []);
  const gameItems = games.map((item) => (
    <React.Fragment key={item.gameId}>
      <Modal
        name={item.gameName}
        okBtn={{
          fn: () => okButton(item),
          text: 'playNow',
        }}
        cancelBtn={null}
      >
        {' '}
        <div>Modal Window</div>
      </Modal>
      <div
        key={item.gameId}
        className={item.gameId === '1560c13f-4707-494d-922b-b49dd1dfacf1' ? 'tournament-game' : ''}
      >
        <div className="tournament-game-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-trophy-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
          </svg>

          {t('tournament')}
        </div>

        <div className="game_box">
          <figure>
            <img src={getGameThumbnail(item)} alt="#" />
          </figure>
        </div>
        <div className="game">
          <h3>{item.gameName}</h3>
        </div>
        <div className="btn-group" role="group">
          <Link className="btn-link demo" to={`/demo/${item.gameId}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-play-circle icon-margin"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
            </svg>
            {t('demoPlay')}
          </Link>
          <button
            className="btn-link play"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            onClick={() => onClick(item)}
            style={{ color: 'white' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-database icon-margin"
              viewBox="0 0 16 16"
            >
              <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
            </svg>
            {t('realPlay')}
          </button>
        </div>
      </div>
    </React.Fragment>
  ));

  return (
    <div id="game" className="casino">
      <h1 className="game-title">{t('ourGames')}</h1>
      <div className="container">
        <div className="row">
          <AliceCarousel
            mouseTracking
            items={gameItems}
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
            responsive={{
              0: {
                items: 1,
              },
              736: {
                items: 2,
              },
              992: {
                items: 3,
              },
              1024: {
                items: 4,
              },
            }}
          />
          <div>
            <Link to="/play">
              <p className="all-games">{t('allGames')}</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
